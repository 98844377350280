.banner {
    background: linear-gradient(rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.58)),
        url('/assets/images/cta-banner.jpeg') no-repeat center center / cover;
    color: white;
}

.banner__container {
    padding: 80px 15px;

    @media (min-width: @screen-md) {
        padding: 135px 80px;
    }

    @media (min-width: @screen-xl) {
        padding: 140px 15px;
    }
}

.banner__row {
    justify-content: center;
}

.banner__col {
    text-align: center;
    .flex-col-xl(8);
}

.banner__subtitle {
    font-weight: bold;
}

.banner__heading {
    &:extend(h1);
    color: white;
    margin-top: 16px;
}

.banner__subheading {
    margin-top: 28px;
}

.banner__btn {
    margin-top: 28px;
}
