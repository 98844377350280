// ant-input-inverse
.ant-form-inverse,
.ant-select-dropdown {
    .ant-input,
    .ant-input-number {
        background: #eff1f5;
        border-color: #eff1f5;
        color: #01101f;

        &:hover,
        &:focus {
            border-color: @primary-color;
        }
    }
    .ant-form-item-has-error {
        .ant-input,
        .ant-input-number {
            &,
            &:hover {
                background: #eff1f5;
                border-color: @error-color;
            }
        }
    }
}

// input-autocomplete-inverse
.ant-form-inverse,
.ant-select-dropdown {
    input {
        &:-webkit-autofill {
            &,
            &:hover,
            &:focus {
                transition: background-color 5000s ease 0s;
                box-shadow: 0 0 0 1000px #eff1f5 inset;
                -webkit-text-fill-color: #01101f;
            }
        }
    }
}

// ant-input-affix-wrapper-inverse
.ant-form-inverse,
.ant-select-dropdown {
    .ant-input-affix-wrapper {
        background: #eff1f5;
        border-color: #eff1f5;

        &:hover,
        &:focus {
            border-color: @primary-color;
        }
    }
    .ant-form-item-has-error {
        .ant-input-affix-wrapper {
            &,
            &:hover {
                background: #eff1f5;
                border-color: @error-color;
            }
        }
    }
}
