.ant-input-suffix {
    color: @normal-color;
}

// ant-input-prefix
.ant-input-prefix,
.ant-input-suffix {
    color: @normal-color;
    text-decoration: none;

    &:focus,
    &:hover,
    &:active {
        text-decoration: none;
    }
}
