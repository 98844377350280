// table th
th {
    .ant-table-thead > tr > & {
        padding: 11px 16px;
        font-size: @font-size-sm;
        font-weight: 600;
        border-bottom: none;

        &[colspan]:not([colspan='1']) {
            text-align: left;
        }
    }
}

td {
    .ant-table-tbody > tr > &,
    .ant-table tfoot > tr > & {
        padding: 24px 16px;
    }
}

// table th & td
th,
td {
    .ant-table-thead > tr > &,
    .ant-table-tbody > tr > &,
    .ant-table tfoot > tr > & {
        @media (max-width: @screen-md-max) {
            font-size: @font-size-sm;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.ant-table-row:hover {
    background-color: darken(@body-background, 10%);
}

// no-padding cell
.cell-no-indent {
    padding: 10px 0 !important;
}

// cell-muted
.cell-muted {
    color: @normal-color;
}

// cell-pending
.cell-pending {
    color: #cc942d;
}

.cell-pending-refund {
    color: @neutral-7;
}

.cell-canceled {
    color: #db2a27;
}

.cell-completed {
    color: @success-secondary;
}

.cell-refunded {
    color: @primary-color;
}

.cell-date {
    span {
        white-space: nowrap;

        &:first-child {
            display: block;

            @media (min-width: @screen-md) {
                display: inline;
            }
        }
    }
}
