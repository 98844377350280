.payment-arrow {
    display: inline-flex;
    flex-shrink: 0;
    color: #5f6467;
}

.payment-buy-section-disabled .payment-arrow {
    color: inherit;
}

.payment-arrow-centered .icon {
    position: relative;
    top: 22px;
}

.payment-arrow__hint {
    display: inline-block;
    font-size: @font-size-sm;
    color: @normal-color;
    margin-right: 28px;
    text-transform: capitalize;
}
