// ant-input-number
.ant-input-number {
    width: 180px;
}

// ant-input-number-handler-wrap
.ant-input-number-handler-wrap {
    display: none;
}

.ant-input-number-input-wrap {
    .ant-input-number-input {
        height: 42px;
        font-size: @font-size-lg;
    }
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
