.retry-list {
    margin-top: 30px;
}

.retry-list__title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
}

.retry-list__methods-single .payment-btn {
    &:hover,
    &:active,
    &:focus {
        cursor: initial;
    }
}
