.verification-info__first-name,
.verification-info__last-name,
.verification-info__state,
.verification-info__zip {
    .flex-col-sm(6);
}

.verification-info__zip-expanded {
    .flex-col-sm(12);
}

.verification-info__dob,
.verification-info__citizenship {
    .flex-col-sm(6);
}

.verification-info__dob {
    .flex-col-md(3);
}

.verification-info__citizenship {
    .flex-col-md(9);
}

.verification-info__ssn {
    margin-bottom: 25px;
}

.verification-info__hasntSsn {
    margin-bottom: 10px;
}

.uploaded-file__file a {
    word-break: break-word;
}

.verification-info__phone {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-right: 10px;
    }
}
