@font-face {
    font-family: 'Default-Icons';
    src: url('/assets/fonts/Default-Icons.eot?db7042d6daefdf647550883b1de558d6#iefix') format('embedded-opentype'),
        url('/assets/fonts/Default-Icons.woff2?db7042d6daefdf647550883b1de558d6') format('woff2'),
        url('/assets/fonts/Default-Icons.woff?db7042d6daefdf647550883b1de558d6') format('woff'),
        url('/assets/fonts/Default-Icons.ttf?db7042d6daefdf647550883b1de558d6') format('truetype');
}

i[class^='icon-']:before,
i[class*=' icon-']:before {
    font-family: Default-Icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-alert:before {
    content: '\f101';
}
.icon-angle-left:before {
    content: '\f102';
}
.icon-angle-right:before {
    content: '\f103';
}
.icon-bank:before {
    content: '\f104';
}
.icon-card:before {
    content: '\f105';
}
.icon-check-circle:before {
    content: '\f106';
}
.icon-close:before {
    content: '\f107';
}
.icon-done:before {
    content: '\f108';
}
.icon-down:before {
    content: '\f109';
}
.icon-edit:before {
    content: '\f10a';
}
.icon-eye-invisible:before {
    content: '\f10b';
}
.icon-eye:before {
    content: '\f10c';
}
.icon-heart-o:before {
    content: '\f10d';
}
.icon-heart:before {
    content: '\f10e';
}
.icon-info-circle:before {
    content: '\f10f';
}
.icon-minus:before {
    content: '\f110';
}
.icon-plus:before {
    content: '\f111';
}
.icon-social-discord:before {
    content: '\f112';
}
.icon-social-facebook:before {
    content: '\f113';
}
.icon-social-instagram:before {
    content: '\f114';
}
.icon-social-linkedin:before {
    content: '\f115';
}
.icon-social-reddit:before {
    content: '\f116';
}
.icon-social-tiktok:before {
    content: '\f117';
}
.icon-social-x:before {
    content: '\f118';
}
