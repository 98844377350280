.ant-radio-group {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.ant-radio-wrapper {
    display: flex;
    margin-bottom: 20px;
}

.ant-radio {
    display: flex;
    align-self: flex-start;
}

.ant-radio-title {
    width: 100%;
    display: inline-flex;
}

.ant-radio-description {
    margin-top: 10px;
    font-size: 1.4rem;
    color: @normal-color;
    white-space: normal;
}

.ant-radio-group-solid {
    display: flex;
    flex-direction: row;

    .ant-radio-button-wrapper {
        background-color: #f2f2f4;
        border-radius: @border-radius-lg;
        border: none;
        width: 40px;

        &:not(:last-child) {
            margin-right: 15px;
        }

        &:not(:first-child):before {
            display: none;
        }
    }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: darken(@primary-color, 5%);
    border-color: darken(@primary-color, 5%);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    background: darken(@primary-color, 10%);
    border-color: darken(@primary-color, 10%);
}

.ant-radio-inner:not(.ant-radio-checked .ant-radio-inner) {
    background-color: white;

    .radio-payment & {
        border-color: lighten(@normal-color, 20%);
    }

    .ant-radio-disabled & {
        background-color: @neutral-3;
        border: 1px solid @neutral-6;
    }
}

.ant-radio-checked .ant-radio-inner:not(.radio-payment) {
    border-color: @primary-color;

    &:after {
        background-color: @primary-color;
    }
}

span.ant-radio + * {
    padding-left: 12px;
}

.ant-radio-wrapper-disabled {
    cursor: not-allowed;
}

// hover
.ant-radio:hover .ant-radio-inner,
.ant-radio:focus .ant-radio-inner,
.ant-radio-inner:hover,
.ant-radio-inner:focus {
    border: 1px solid @neutral-10;
}

// disabled
.ant-radio-disabled.ant-radio-checked .ant-radio-inner {
    background-color: @neutral-3;
    border: 1px solid @neutral-6;
}

// disabled + checked
.ant-radio-disabled.ant-radio-checked .ant-radio-inner:not(.radio-payment) {
    border: 1px solid @primary-4 !important;
    background: @white;

    &:after {
        background-color: @primary-4;
    }
}

// error (not disabled)
.ant-form-item-has-error:not(.ant-radio-disabled) .ant-radio-inner {
    border: 1px solid @error-color;
}
