.payment-section-item {
    padding: 24px 20px;
    gap: 30px 90px;
    justify-content: space-between;
    cursor: default;
    .bordered-list(@divider-color);

    &:not(.payment-section-with-details) {
        padding-top: 35px;
        padding-bottom: 35px;
    }
}
