.ant-back-top {
    z-index: 1100;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: @grayscale-black-fade-6;

    &:hover,
    &:focus {
        cursor: pointer;
        background: @grayscale-black-fade-8;
    }
}

.ant-back-top-inner__icon {
    color: @white;
}
