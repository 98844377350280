.payment-collapse {
    font-size: @font-size-base;

    .payment-collapse__panel {
        padding: 0 0 10px;
        background-color: @white;

        .ant-collapse-header {
            padding: 0;
            position: relative;
            top: 0;
            border: none;

            .anticon {
                display: none;
            }
        }

        .ant-collapse-content {
            margin-top: 0;
            border: 0;
        }

        .ant-collapse-item {
            border: none;
            padding: 0;

            .ant-collapse-content-box {
                padding: 0;
            }
        }
    }

    .ant-collapse-borderless > .payment-collapse__panel.ant-collapse-item {
        border: none;
        padding: 0;

        .ant-collapse-content-box {
            padding: 0;
        }
    }

    .ant-collapse-item-active .ant-collapse-header .payment-section-item {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .payment-collapse__panel .ant-collapse-content .payment-section-item {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .payment-item-collapse__toggle {
        padding-left: 12px;
        padding-right: 12px;
    }

    .payment-collapse__panel.ant-collapse-item-active .icon-down {
        transform: rotate(180deg);
    }
}
