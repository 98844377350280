// hiw-card
.hiw-card {
    background: @primary-2;
    border-radius: 16px;
    margin-bottom: 30px;
    padding: 30px;

    @media (min-width: @screen-xl) {
        margin-bottom: 0px;
        height: 100%;
    }
}

// hiw-card index
.hiw-card__index {
    margin-bottom: 30px;
}

// hiw-card number
.hiw-card__number {
    border-radius: 20px;
    background-color: @black;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: @white;
    text-align: center;
}

// hiw-card__text
.hiw-card__text {
    margin-bottom: 10px;

    @media (min-width: @screen-xl) {
        margin-bottom: 0px;
    }
}

// hiw-card title
.hiw-card__title {
    &:extend(h2);

    @media (min-width: @screen-lg) {
        font-size: @heading-3-size;
    }

    &:after {
        @media (max-width: @screen-sm-max), (min-width: @screen-xl-min) {
            display: block;
            content: '';
            border-bottom: 1px solid black;
            width: 45px;
            height: 1px;
            margin-top: 24px;
        }
    }
}

// hiw-card description
.hiw-card__descr {
    margin: 24px 0px 0px;

    @media (min-width: @screen-md) {
        margin: 20px 0px 0px;
    }

    @media (min-width: @screen-xl) {
        margin: 24px 0px 10px;
    }
}
