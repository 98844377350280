// nz-upload
nz-upload {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-bottom: 10px;

    @media (min-width: @screen-sm) {
        flex-direction: row-reverse;
    }
}

// ant-upload-select
.ant-upload-select {
    margin-top: 20px;

    @media (min-width: @screen-sm) {
        margin-top: 0;
    }
}

// .ant-upload-list
.ant-upload-list {
    max-width: 252px;

    @media (min-width: @screen-md) {
        max-width: 176px;
    }
}

.ant-upload-list-text-container {
    margin-right: 24px;
}

// ant-upload-list-item-info-hover
.ant-upload-list-item-info {
    .ant-upload-list-item:hover & {
        background-color: transparent;
    }
}

// ant-upload-text-icon
.ant-upload-text-icon {
    display: none;
}

// ant-upload-list-item-name
.ant-upload-list-item-name {
    .ant-upload-list-text &,
    .ant-upload-list-picture & {
        padding-left: 0;
    }
}

// ant-upload-list-item-card-actions-btn
.ant-upload-list-item-card-actions-btn {
    opacity: 1;

    &.ant-btn {
        background-color: transparent;

        &:focus,
        &:hover,
        &:active {
            background-color: transparent;
            border-color: transparent;
        }
    }
}
