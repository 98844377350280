// page-title
.page-title {
    margin-bottom: 20px;

    @media (min-width: @screen-lg) {
        margin-bottom: 40px;
    }
}

// page-title-sticky
.page-title-sticky {
    @media (min-width: @screen-lg) {
        position: sticky;
        top: 120px;
    }
}
