.ant-btn-dangerous.ant-btn-primary {
    &[disabled],
    &[disabled]:hover {
        background: @rose-dark;
        border-color: @rose-dark;
    }

    &:hover,
    &:focus,
    &:active {
        background: @error-color-dark;
        border-color: @error-color-dark;
    }
}
