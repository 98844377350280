// country-picker select
.country-picker__select {
    min-width: 100px;
}

// country-picker image
.country-picker__img {
    display: inline-block;
    vertical-align: middle;
    width: 26px;
    height: 26px;
    margin: 0 10px 3px 0;
}

// country-picker dropdown
.ant-select-dropdown {
    .country-picker & {
        width: 456px;
        margin-left: -1px;
    }
}

// country-picker viewport
.ant-select-dropdown .full-width {
    .country-picker & {
        min-height: 220px;
    }
}

// country-picker item
.ant-select-item {
    .country-picker & {
        font-size: 16px;
        line-height: 24px;
        padding: 10px 28px;
    }
}

// country-picker item-content
.ant-select-item-option-content {
    .country-picker & {
        display: flex;
        align-items: center;
    }
}

// country-picker search
.ant-select-item-option-disabled {
    .country-picker & {
        cursor: default;
    }
}

// country-picker empty
.ant-empty {
    .country-picker & {
        margin: 0 auto;
    }
}

// country-picker empty-image
.ant-empty-image {
    .country-picker & {
        height: 100px;
        margin-bottom: 8px;
    }
}

// country-picker empty-description
.ant-empty-description {
    .country-picker & {
        font-size: inherit;
        color: #01101f;
        line-height: 2.2rem;
    }
}
