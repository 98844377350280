// cta
.cta {
    text-align: center;
    color: @white;
}

// cta container
.cta__container {
    @media (max-width: @screen-xs-max) {
        padding: 0;
    }
}

// cta wrap
.cta__wrap {
    background: @black;
    padding: 80px 15px;

    @media (min-width: @screen-sm) {
        border-radius: 16px;
    }
    @media (min-width: @screen-xl) {
        padding: 105px 15px;
    }
}

// cta heading
.cta__heading {
    &:extend(h1);
    color: inherit;
}

// cta description
.cta__descr {
    margin-bottom: 30px;

    @media (min-width: @screen-lg) {
        font-size: 20px;
    }
}
