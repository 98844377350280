.bordered-list(@srcBorderColor) {
    border-bottom: none;
    border-left: @border-width-base solid @srcBorderColor;
    border-right: @border-width-base solid @srcBorderColor;

    &:first-of-type {
        border-top: @border-width-base solid @srcBorderColor;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    &:last-of-type {
        border-bottom: @border-width-base solid @srcBorderColor;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    & + & {
        border-top: @border-width-base solid @srcBorderColor;
    }
}
