// ant-modal
.ant-modal-sm {
    width: 450px !important;
}

// ant-modal-header-sm
.ant-modal-header {
    .ant-modal-sm & {
        @media (min-width: @screen-md) {
            padding-bottom: 24px;
        }
    }
}
