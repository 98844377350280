// item-card
.item-card {
    .layer-fixed(auto, 0, 0, 0, 101);
    box-shadow: 0 -2px 17px 0 rgba(0, 0, 0, 0.11);
    background: @body-background;

    @media (min-width: @screen-lg) {
        position: sticky;
        top: 114px;
        z-index: 0;
        box-shadow: none;
    }
}

.item-card__progress {
    display: none;

    @media (min-width: @screen-lg) {
        display: block;
    }
}

// item-card-body
.ant-card-body {
    .item-card & {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media (min-width: @screen-lg) {
            flex: 1 0 auto;
            flex-direction: column;
            align-items: stretch;
            padding: 0 0 25px 0;
        }

        &:before,
        &:after {
            content: unset;
        }
    }
}

// item-card title
.item-card__title {
    font-size: 34px;
    margin-bottom: 5px;
}

// item-card share
.item-card__share {
    font-size: @font-size-base;
    font-weight: 400;
    margin-left: -3px;
}

// item-card icon
.item-card__icon {
    font-size: @font-size-sm;
    color: @normal-color;
    margin-left: 5px;

    &:hover,
    &:active {
        color: darken(@normal-color, 15%);
    }
}

// item-card min
.item-card__min {
    justify-content: center;
    font-size: @font-size-sm;
    color: @normal-color;
    display: none;

    @media (min-width: @screen-lg) {
        display: flex;
    }
}

.item-card__min-fixed {
    display: block;

    @media (min-width: @screen-lg) {
        display: none;
    }
}

.item-card__min-fixed .item-card__investment {
    display: none;

    @media (min-width: @screen-md) {
        display: inline;
    }
}

// item-card divider
.item-card__divider {
    display: none;
    margin: 20px 0;

    @media (min-width: @screen-lg) {
        display: block;
        min-width: 100%;
        width: auto;
    }
}

// item-card divider-full
.item-card__divider-full {
    margin-left: -25px;
    margin-right: -25px;
}

// item-card row
.item-card__row {
    display: none;
    font-size: 16px;
    margin: 24px 0 20px 0;

    @media (min-width: @screen-lg) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

// item-card favorite
.item-card__favorite {
    .layer(12px, 12px, auto, auto, 1);
    width: auto;

    > .ant-btn {
        background: rgba(@white, 0.5);
        border: none;
        font-size: 20px;
        color: @text-color;
        height: 48px;
        width: 48px;

        &:hover,
        &:active,
        &:focus {
            background-color: rgba(@white, 0.7);
            color: @text-color;
        }
    }
}
