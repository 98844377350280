// back-link
.back-link {
    font-size: @font-size-sm;
    padding: 0px;

    @media (min-width: @screen-md) {
        padding-bottom: 10px;
    }

    @media (min-width: @screen-lg) {
        padding-bottom: 0px;
    }
}

.header-mini .back-link {
    padding: 30px 0;
}

// back-link link
.back-link__link {
    color: @text-color;
    text-decoration: none;

    &:active,
    &:hover {
        color: lighten(@text-color, 20%);
        text-decoration: none;
    }
}

// back-link icon
.back-link__icon {
    font-size: @font-size-xs;
    margin-right: 10px;
}
