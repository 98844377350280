.portfolio-total {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: @screen-md) {
        margin-right: auto;
        align-self: center;
        align-items: center;
        flex-direction: row;
    }

    @media (min-width: @screen-lg) {
        margin-right: 0;
    }
}

.portfolio-total__items {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        margin-bottom: 15px;
        margin-right: 0;

        @media (min-width: @screen-md) {
            margin-bottom: 0;
            margin-right: 66px;
        }
    }
}

.portfolio-total__value {
    font-size: 20px;
    font-weight: 500;

    @media (min-width: @screen-md) {
        font-size: 24px;
    }
}

.portfolio-total__descr {
    font-size: 16px;
    color: @neutral-7;
}
