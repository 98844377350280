.dividend-alert {
    .layer-fixed(0, 0, auto, 0, 101);
    background-color: @warning-color2;
    text-align: center;
}

.dividend-alert .ant-alert {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: @font-size-sm;
    line-height: 16px;
}

.dividend-alert .ant-alert .ant-btn-text {
    background: transparent;
    height: auto;
    padding: 0;
    border-radius: 0px;
    border: none;
    border-bottom: solid 1px @black;
    text-transform: capitalize;
}

// layout header with alert
.app-layout.with-dividend-alert .ant-layout-header {
    .layer-fixed(54px, 0, auto, 0, 101);

    @media (min-width: @screen-sm) {
        .layer-fixed(39px, 0, auto, 0, 101);
    }
}

.app-layout.with-dividend-alert .ant-layout-content {
    margin-top: 90px;

    @media (min-width: @screen-md) {
        margin-top: 120px;
    }
}

// my accounts > Payment Method tab
.ant-tabs-tab-btn .payment-method-tab {
    display: flex;
    align-items: center;
}

// my-account payment method tab icon
.payment-method-tab .payment-method-tab-icon {
    color: @warning-icon-color;
    margin-left: 8px;
    font-size: @font-size-lg;
}

// dividend alert message on top of bank method
.bank-info__warning-msg .ant-alert {
    background-color: @warning-color2;
    font-size: @font-size-sm;
    padding: 12px 16px;
    margin-bottom: 16px;
    line-height: 1.14;
    border: none;
}
