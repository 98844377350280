// hiw
.hiw {
    margin-bottom: 75px;

    @media (min-width: @screen-lg) {
        margin-bottom: 100px;
    }
}

.hiw__row {
    row-gap: 30px;
}

.hiw__col {
    .flex-col-md(8);
    .flex-col-xl(6);
}

.hiw__col-cards {
    .flex-col-md(12);
    .flex-col-xl(4);
}

.hiw__text {
    @media (min-width: @screen-xl) {
        padding-right: 50px;
    }
}

.hiw__heading {
    &:extend(h1);
    font-size: 34px;

    @media (min-width: @screen-xl) {
        font-size: 48px;
    }
}

.hiw__subheading {
    margin: 12px 0 48px 0;

    @media (min-width: @screen-md) {
        margin: 24px 0 62px 0;
    }

    @media (min-width: @screen-xl) {
        margin: 28px 0 56px 0;
    }
}

.hiw__btn {
    margin-top: 20px;

    @media (min-width: @screen-md) {
        font-size: 40px;
    }
}
