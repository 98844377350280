.payment-item-details {
    .list-unstyled();
}

.payment-item-details__item {
    font-size: clamp(@font-size-sm, 3vw, @font-size-base);
    margin-top: 4px;

    &:first-of-type {
        margin-top: 12px;
    }
}
