.payment-section {
    align-items: center;
    background-color: @white;
    display: flex;
    flex-wrap: wrap;
    font-size: clamp(@font-size-sm, 3vw, @font-size-base);
    font-weight: 400;
    height: auto;
    letter-spacing: normal;
    text-align: left;
    text-transform: none;
    white-space: normal;
}

.payment-section__title {
    display: flex;
    column-gap: 5px;
    align-items: center;
    flex-grow: 1;
    margin-right: 10px;
    font-size: @font-size-base;
    text-transform: capitalize;
    overflow: hidden;
}

.payment-section__title-block {
    display: block;
    font-weight: bold;
}

.payment-section:not(&.payment-section-disabled) .payment-section__title-block {
    color: @heading-color;
}

.payment-section .card-icon {
    margin: 0;
}

.payment-section .card-icon__item {
    margin-bottom: 0;
    margin-right: 10px;
}

.payment-section-disabled {
    color: @neutral-7;

    &:hover {
        cursor: not-allowed;
    }
}

.payment-section-bordered {
    .bordered-list(@divider-color);
}
