// steps-action
.steps-action {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 30px;
    border-top: solid 1px @divider-color;
}

// steps-action-with-header
.steps-action-with-header {
    display: flex;
    flex-direction: column;
    padding-top: 12px;

    .steps-action {
        border-top: none;
    }
}

// steps-action-header-text
.steps-action-header-text {
    font-size: @font-size-sm;

    @media (min-width: @screen-md) {
        letter-spacing: -0.1px;
    }
}
