// ant-tag
.ant-tag {
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 7px 16px;
}

// ant-tag-status
.ant-tag-status {
    background-color: @warning-color;
    color: @text-color;
    text-transform: none;
}
