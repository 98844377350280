// ant-card-transparent
.ant-card-transparent {
    background: transparent;
    box-shadow: none;
}

// ant-card-head-transparent
.ant-card-head {
    .ant-card-transparent & {
        border: 0;
        padding: 0;
    }
}

// ant-card-body
.ant-card-body {
    .ant-card-transparent & {
        padding-left: 0;
        padding-right: 0;
    }
}
