.filter-mobile__collapse .ant-collapse-header {
    font-weight: bold;
    position: relative;
    background-color: @neutral-2;
    padding: 0 20px;
    height: 70px;
    display: flex;
    align-items: center;
}

.filter-mobile__collapse .ant-collapse-header {
    border-top: 1px solid @border-color-split;
    border-bottom: 1px solid @border-color-split;
}

.filter-mobile__collapse.is-opened .ant-collapse-header {
    border-top: 1px solid @border-color-split;
    border-bottom: 0;
}

.filter-mobile__collapse .ant-collapse-header .anticon {
    position: absolute;
    right: 20px;
}

.filter-mobile__collapse .ant-collapse-content {
    background-color: @neutral-2;
    padding: 0 20px;
}
