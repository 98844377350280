// ant-select-selector
.ant-select-selector {
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) & {
        box-shadow: none;
    }
}

// ant-select-item
.ant-select-item {
    color: #01101f;
}

// ant-select-selection-item
.ant-select-selection-item {
    color: @text-color;

    .ant-select-single.ant-select-open & {
        color: darken(@text-color, 10%);
    }
}

// ant-select-arrow
.ant-select-arrow {
    transition: color 0.3s ease 0s;
    font-size: 8px;
    color: @text-color;
    height: 8px;
    margin-top: -4px;

    .ant-select-open & {
        color: darken(@text-color, 10%);
    }
}
