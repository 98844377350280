// ant-picker
.ant-picker {
    display: flex;
}

// ant-picker-suffix
.ant-picker-suffix {
    color: @text-color;
}

// ant-picker-clear
.ant-picker-clear {
    background-color: @picker-bg;
}

// ant-picker-header
.ant-picker-header {
    color: rgba(@black, 0.85);

    button {
        color: rgba(@black, 0.25);

        &:hover,
        &:active {
            color: rgba(@black, 0.85);
        }
    }
}

// ant-picker-header-view
.ant-picker-header-view {
    button {
        color: inherit;

        &:hover,
        &:active {
            color: @primary-color;
        }
    }
}

// .ant-picker-content
.ant-picker-content {
    th {
        color: rgba(@black, 0.85);
    }
}

// ant-picker-cell
.ant-picker-cell {
    color: rgba(@black, 0.25);
}

// ant-picker-cell-in-view
.ant-picker-cell-in-view {
    color: rgba(@black, 0.85);
}
