.flex-col-width(@columns) {
    flex: 0 0 percentage((@columns / @grid-columns));
    max-width: percentage((@columns / @grid-columns));
}

.flex-col-sm(@sm: 12) {
    @media (min-width: @screen-sm) {
        .flex-col-width(@sm);
    }
}

.flex-col-md(@md: 12) {
    @media (min-width: @screen-md) {
        .flex-col-width(@md);
    }
}

.flex-col-lg(@lg: 12) {
    @media (min-width: @screen-lg) {
        .flex-col-width(@lg);
    }
}

.flex-col-xl(@xl: 12) {
    @media (min-width: @screen-xl) {
        .flex-col-width(@xl);
    }
}
