// dropdown-menu-item
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    padding: 10px 40px;

    &:active,
    &:hover {
        background-color: @menu-item-active-bg;
    }
}

// dropdown-menu-item-selected
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
    position: relative;

    &:before {
        .layer(10px, auto, auto, 16px);
        &:extend(.icon-check:before);
        display: inline-block;
        font-family: 'Default-Icons';
        font-size: 13px;
        color: @primary-color;
    }
    &,
    & > a {
        color: inherit;
        background-color: inherit;
    }
}

// dropdown-icon
.dropdown-icon {
    font-size: 9px;
    margin-left: 5px;
}

// dropdown-menu-item-selected-tabs
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
    .nz-tabs-dropdown.ant-dropdown & {
        &:before {
            content: unset;
        }
    }
}
