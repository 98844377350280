// card-icon
.card-icon {
    float: left;
    margin: -3px 5px 5px 0px;
}

// card-icon-flex
.card-icon-flex {
    float: none;
    display: flex;
    flex-wrap: wrap;
    margin: -12px -7px 0 -7px;
}

// card-icon-header
.card-icon-header {
    float: none;
    display: flex;
    flex-wrap: wrap;
    margin: 15px -29px -30px -7px;
}

// card-icon item
.card-icon__item {
    margin-bottom: 12px;

    svg-icon-sprite {
        display: inline-block;
        width: 48px;
        height: 34px;
    }
}

// card-icon item-header & flex
.card-icon__item {
    .card-icon-header &,
    .card-icon-flex & {
        margin-left: 7px;
        margin-right: 7px;
    }
}
