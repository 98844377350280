.invest-card {
    background-color: @neutral-2;
    margin-bottom: 10px;

    @media (min-width: @screen-md) {
        margin-bottom: 30px;
    }

    @media (min-width: @screen-xl) {
        height: 100%;
    }
}

.invest-card .ant-card-body {
    background-color: @neutral-2;
    padding-left: 0px;
}

.invest-card__icon {
    background: @primary-3;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    padding: 8px;
}

.invest-card__title {
    &:extend(h2);
    margin-top: 16px;

    @media (min-width: @screen-lg) {
        font-size: @heading-3-size;
    }
}

.invest-card__descr {
    margin: 24px 0px 0px;

    @media (min-width: @screen-md) {
        margin: 20px 0px 0px;
    }

    @media (min-width: @screen-xl) {
        margin: 24px 0px 10px;
    }
}
