.check-account__icon {
    height: 60px;
    width: 60px;
    margin-bottom: 45px;

    @media (min-width: @screen-lg) {
        margin-bottom: 54px;
    }
}

.check-account__col {
    .flex-col-md(8);
}

.check-account__title {
    &:extend(h2);
}

.check-account__descr {
    margin-bottom: 40px;
}
