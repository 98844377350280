// footer
.footer {
    color: @normal-color;
    padding: 60px 5px 30px;
    position: relative;
    z-index: 1000;

    @media (min-width: @screen-md) {
        padding: 70px 0 73px;
        height: 220px;
    }

    @media (min-width: @screen-lg) {
        padding: 70px 0 73px;
        height: auto;
    }

    a {
        color: @normal-color;
        &:hover,
        &:active {
            color: @primary-color;
            text-decoration: underline;
        }
    }
}

// footer text
.footer__text {
    font-size: 12px;
    margin-bottom: 20px;
}

// footer copy
.footer__copy {
    font-size: @font-size-sm;
    margin-bottom: 20px;

    a {
        color: @normal-color;
    }

    @media (min-width: @screen-md) {
        margin-bottom: 0;
    }
}

.footer__copy-divider {
    margin-bottom: 20px;
    @media (min-width: @screen-md) {
        display: none;
    }
}

.footer__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    @media (min-width: @screen-lg) {
        flex-direction: row;
    }
}

// footer social copy
.footer__rights {
    font-size: @font-size-sm;

    @media (min-width: @screen-lg) {
        width: 100%;
        text-align: right;
    }

    @media (max-width: @screen-lg) {
        margin-top: 20px;
        text-align: center;
    }
}

// footer__links
.footer__links {
    font-size: @font-size-sm;
    margin-bottom: 30px;
    order: 1;
    width: 100%;
    display: flex;
    align-self: flex-start;
    flex-direction: column;

    a {
        text-decoration: none;
    }

    @media (min-width: @screen-md) {
        margin-bottom: 0;
        order: 0;
        width: auto;
        flex-direction: row;
    }

    @media (min-width: @screen-lg) {
        align-self: flex-end;
    }
}

.footer__link {
    display: inline-block;
    margin-right: 24px;
    margin-bottom: 36px;
    order: 0;
    &:first-child {
        margin-right: 44px;
        margin-bottom: 0px;
        order: 1;
    }

    @media (min-width: @screen-md) {
        order: 1;
        margin-bottom: 0;
    }
}

// footer__icons
.footer__icons {
    margin-bottom: 36px;
    font-size: 24px;
    order: 0;
    align-self: flex-start;

    @media (min-width: @screen-md) {
        margin-bottom: 0px;
        order: 1;
    }

    @media (min-width: @screen-md) and (max-width: @screen-lg) {
        align-self: flex-end;
        margin-bottom: 36px;
        margin-top: -90px;
    }
}

// footer__icon
.footer__icon {
    display: inline-block;
    margin-right: 32px;

    a {
        color: @text-color;

        &:hover,
        &:active {
            color: @text-color;
            opacity: 0.8;
        }
    }

    @media (min-width: @screen-md) {
        margin-right: 20px;
    }
}

.footer__icon-twitter {
    margin-top: 3px;
}
