.payment-btn-details .payment-btn__row {
    width: 100%;
}

.payment-btn-details .payment-btn__title {
    font-weight: 700;
    font-size: @font-size-base;
    color: @black;
}

.payment-btn-details .payment-btn__descr {
    font-size: @font-size-base;
    color: @black;
}
