.retry-list-details {
    width: 100%;
    gap: 16px;
    flex-direction: column;
    display: flex;
}

.retry-list-details__title {
    font-weight: 700;
    font-size: @font-size-base;
    color: @black;
}

.retry-list-details__description {
    font-size: @font-size-base;
    color: @black;
}

.retry-list-details__fee {
    text-transform: capitalize;
    font-size: @font-size-sm;
    color: @normal-color;
}
