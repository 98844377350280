.purchase-summary {
    margin-top: 30px;

    @media (min-width: @screen-lg) {
        margin-top: 0;
        margin-bottom: 10px;
    }
}

.purchase-summary__card {
    border: none;
    background-color: @image-bg;
}

.purchase-summary__card .ant-card-body {
    padding: 28px 27px 28px 28px;
}

.purchase-summary__title {
    &:extend(h3);
    margin-bottom: 24px;
}

.purchase-summary__info {
    display: flex;
    flex-direction: column;

    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        gap: 45px;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.purchase-summary__stats {
    flex: 1;
}

.purchase-summary__img {
    margin-bottom: 0;
    width: 100px;
    margin-right: 24px;

    @media (min-width: @screen-md) {
        margin-right: 0;
        margin-bottom: 16px;
        width: 260px;
    }

    @media (min-width: @screen-lg) {
        width: initial;
    }
}

.purchase-summary__subtitle {
    font-weight: bold;
}

.purchase-summary__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.purchase-summary__row:not(:last-child) {
    margin-bottom: 16px;
}

.purchase-summary__preview {
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        .flex-col-width(6);
    }
}

.purchase-summary__preview .purchase__divider {
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        display: none;
    }
}

.purchase-summary__icon {
    color: @neutral-7;
    cursor: pointer;
    font-size: 14px;
    margin-left: 4px;
}

.purchase-summary__label-fee {
    display: flex;
    gap: 5px;
    align-items: center;
}

.purchase-summary__text-total {
    font-weight: bold;
}
