// steps-note
.steps-note {
    border-top: 1px solid @divider-color;
    padding-top: 12px;
    padding-bottom: 20px;
    font-size: 14px;
}

.steps-note__link {
    text-decoration: none;
}
