// info-block
.info-block {
    margin-bottom: 40px;
}

.info-block__heading {
    &:extend(h5);
}

// Modifiers
.info-block-tax {
    margin: 32px 0 80px 0;
}

.info-block-muted {
    color: @normal-color;
}
