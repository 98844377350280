.retry-summary {
    margin-top: 8px;
}

.retry-summary__title {
    font-size: 25px;
    font-weight: 500;
    line-height: 1;
}

.retry-summary-menu {
    margin: 0;
    padding: 0;
}

.retry-summary-menu__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}
