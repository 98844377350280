// ant-layout-auth
.ant-layout-auth {
    position: relative;
    margin: 15px 0 0 0;
}

// ant-layout-content-auth
.ant-layout-content {
    .ant-layout-auth & {
        flex-grow: 0;
        margin-top: 0;
        padding: 15px;

        @media (min-width: @screen-md) {
            padding: 30px;
        }
    }
}
