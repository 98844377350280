// payment-header
.payment-header {
    .layer(0, 0, auto, 0);
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.payment-header .ant-modal-close-x {
    font-size: @font-size-base;
}
