// img-responsive
.img-responsive {
    .img-responsive();
}

// hide-mobile
.hide-mobile {
    @media (max-width: @screen-md-max) {
        display: none !important;
    }
}

// hide-desktop
.hide-desktop {
    @media (min-width: @screen-lg) {
        display: none !important;
    }
}

// text-left
.text-left {
    text-align: left;
}

// text-center
.text-center {
    text-align: center;
}
