// purchase-checklist
.purchase-checklist {
    .list-unstyled();
}

// purchase-checklist item
.purchase-checklist__item {
    display: flex;
    align-items: center;
    margin: 3px 0;
}

// purchase-checklist icon
.purchase-checklist__icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
}
