.ant-alert {
    font-size: 14px;
    padding: 16px;
}

.ant-alert-block {
    width: 100%;
}

.ant-alert-warning {
    background-color: @warning-color2;
    border-color: @warning-color2;
    font-size: 14px;
    padding: 16px;
}
