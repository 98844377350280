.payment-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.payment-item-content__header {
    flex-grow: 1;
    font-size: @font-size-base;
    text-transform: none;
}

.payment-item-content__bold {
    font-weight: 600;
    text-transform: capitalize;
}
