.regcf-limit__descr,
.regcf-limit__alert,
.regcf-limit .ant-checkbox-wrapper {
    font-size: @font-size-sm;
}

.regcf-limit .ant-modal {
    width: 620px !important;
}

.regcf-limit__descr {
    color: @normal-color;
}

.regcf-limit__descr a {
    color: @normal-color;
}

.regcf-limit__alert {
    margin: 24px 0;
}
