.regcf-preview {
    color: @normal-color;
    background-color: @image-bg;
    padding: 15px;
    font-size: @font-size-sm;
}

.regcf-preview__row {
    display: flex;
    gap: 12px;
}

.regcf-preview__icon a {
    color: @normal-color;
}

.regcf-preview__limit {
    &:extend(h3);
    margin: 12px 0 0;
}
