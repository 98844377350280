.regcf-form {
    margin: 24px 0 20px;
    font-size: @font-size-sm;

    @media (min-width: @screen-md) {
        margin: 24px 0 10px;
    }
}

.regcf-form .ant-form-item {
    margin-bottom: 12px;
    font-size: @font-size-sm;
}

.regcf-form .ant-input-number {
    width: 100%;
}

.regcf-form__row-recalculate,
.regcf-form__row-additional {
    display: flex;
    flex-direction: column;

    @media (min-width: @screen-md) {
        flex-direction: row;
    }
}

.regcf-form__row-additional {
    gap: 0;

    @media (min-width: @screen-md) {
        gap: 18px;
    }
}

.regcf-form__row-additional .ant-form-item {
    flex: 1;
}

.regcf-form__row-recalculate {
    align-items: initial;
    justify-content: space-between;

    @media (min-width: @screen-md) {
        align-items: center;
    }
}

.regcf-form__recalculate .ant-btn {
    text-transform: uppercase;
    color: @black;
    padding: initial;

    @media (min-width: @screen-md) {
        padding: 6px 8px;
    }
}
