// share-picker
.share-picker {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: @screen-md) {
        justify-content: initial;
    }
}

// share-picker button
.share-picker__btn {
    &,
    &:focus {
        background-color: darken(@body-background, 10%);
        border-color: @border-color-base;
        border-radius: 50%;
        width: 44px;
        height: 44px;
        padding: 0;

        @media (min-width: @screen-sm) {
            width: 32px;
            height: 32px;
        }
    }
    &:hover,
    &:active {
        background: @body-background;
    }
    &[disabled] {
        &,
        &:focus,
        &:hover,
        &:active {
            background: @body-background;
            border-color: @border-color-base;
            color: @text-color;
            opacity: 0.5;
        }
    }
    > .icon {
        font-size: @font-size-xs;
        line-height: 30px;
    }
}

// share-picker input
.share-picker__input {
    width: 40px;
    border: none;
    margin: 0 5px;

    &:focus,
    &.ant-input-number-focused {
        box-shadow: none;
    }
    .ant-input-number-input {
        text-align: center;
        padding: 0;
    }
    &.ant-input-number-disabled {
        background-color: @white;
        color: @neutral-7;
    }
}

.share-picker__input-error {
    color: @error-color;
    font-weight: bold;
}

// share-picker multiplier
.share-picker__multiplier {
    margin-left: 15px;

    @media (max-width: @screen-sm-max) {
        display: none;
    }
}
