// ant-form-text
.ant-form-text {
    .ant-form & {
        font-size: 1.4rem;
        color: @normal-color;
        margin-top: 5px;
    }
}

// ant-form-text-disabled
.ant-form-text {
    .ant-form-item-disabled & {
        opacity: 0.6;
    }
}
