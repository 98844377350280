.info-list {
    .list-unstyled();
    margin-bottom: 18px;

    @media (min-width: @screen-md) {
        margin-bottom: 0px;
    }
}

.info-list__item {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;

    a {
        display: inline-block;
    }
}

.info-list__label {
    font-weight: bold;
}
