.ant-input {
    &:disabled,
    .ant-input[disabled] {
        border: 1px solid @neutral-6;
        background: @neutral-3;
    }
}

// input-autocomplete
input {
    &:-webkit-autofill {
        &,
        &:hover,
        &:focus {
            transition: background-color 5000s ease 0s;
            box-shadow: 0 0 0 1000px @input-bg inset;
            -webkit-text-fill-color: @text-color;
        }
    }
}
