.purchase__summary {
    .flex-col-lg(4);
}

.purchase__heading {
    &:extend(h2);
    margin-top: 12px;
}

.purchase__subheading {
    &:extend(h4);
    margin: 48px 0 24px;
    font-size: @heading-3-size;
    font-weight: 500;
}

.purchase__row-add:not(:last-child) {
    margin-bottom: 24px;
}

.purchase__info {
    .flex-col-lg(8);
}

.purchase-methods .purchase__info {
    .flex-col-md(6);
}

.purchase__descr {
    &:extend(p);
}

.purchase__descr-faded {
    color: @normal-color;

    a {
        color: @normal-color;
        &:hover,
        &:active {
            color: @primary-color;
            text-decoration: underline;
        }
    }
}

.purchase__divider {
    height: 20px;
}

.purchase__alert {
    margin-bottom: 20px;
}
