// header-mini
.header-mini {
    margin-bottom: 0px;

    @media (min-width: @screen-md) {
        .layer(0, 0, auto, 0);
        padding-top: 16px;
        margin-bottom: 30px;
    }
}
