.verification__section {
    .flex-col-lg(9);
    .flex-col-xl(8);
}

.verification__title {
    &:extend(h2);
}

.verification__heading {
    &:extend(h3);
}

// verification subtitle
.verification__subtitle {
    &:extend(h5);
    margin: 40px 0 20px;
}

.verification__subtitle-radio {
    margin-bottom: 0;
}

// verification descr
.verification__descr {
    color: @normal-color;
    margin-bottom: 20px;
}

// verification divider
.verification__divider {
    margin: 15px 0 50px;
}

// verification icon
.verification__icon {
    margin-bottom: 40px;

    .icon {
        height: 58px;
        width: 58px;
    }
}

.verification__steps {
    .flex-col-width(7);
    .flex-col-lg(9);
    .flex-col-xl(8);
}

.verification__steps-action {
    .flex-col-lg(9);
    .flex-col-xl(8);
}
