.payment-funds__trigger {
    margin-top: 24px;
}

.payment-funds__heading {
    .payment-btn__heading();
}

.payment-funds__submit {
    margin-top: 12px;
}

.payment-funds__alert {
    margin-top: 20px;
}

.payment-funds__input {
    width: 100%;
}

.payment-funds__descr {
    margin: 10px 0 40px;
}
