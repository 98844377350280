.ant-btn-rose {
    background: @rose;
    color: @text-color;

    &[disabled],
    &[disabled]:hover {
        background: @rose;
        color: @text-color-disabled;
        border-color: @rose;
    }

    &:hover,
    &:focus,
    &:active {
        background: @rose-dark;
        color: @text-color;
        border-color: @rose-dark;
    }
}
