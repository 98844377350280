.verification-finra {
    position: relative;
}

.verification-finra__yes {
    height: auto;
}

.verification-finra__yes-required {
    height: 150px;

    @media (min-width: 510px) {
        height: 130px;
    }
}

.verification-finra__yes-error {
    height: 160px;

    @media (min-width: 510px) {
        height: 140px;
    }
}

.verification-finra__relation {
    position: absolute;
    top: 75px;
    width: 100%;
    font-size: @font-size-sm;
}

.verification-finra__label {
    margin-bottom: 8px;
}
