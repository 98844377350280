// text-image
.text-image {
    margin-bottom: 75px;

    @media (min-width: @screen-lg) {
        margin-bottom: 100px;
    }
}

// text-image subtitle
.text-image__subtitle {
    &:extend(h6);
    color: @primary-color;
    text-transform: uppercase;
}

// text-image heading
.text-image__heading {
    &:extend(h1);
}

// text-image description
.text-image__descr {
    @media (min-width: @screen-lg) {
        font-size: 20px;
    }
    > p {
        margin-bottom: 0;
    }
}

// text-image col-txt
.text-image__col-text {
    margin-bottom: 30px;
    .flex-col-md(6);
    .flex-col-lg(5);

    @media (min-width: @screen-lg) {
        margin: 0 percentage((1 / @grid-columns)) 0 0;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

// text-image col-txt-right
.text-image__col-text {
    .text-image-right & {
        @media (min-width: @screen-md) {
            order: 2;
        }
        @media (min-width: @screen-lg) {
            margin: 0 0 0 percentage((1 / @grid-columns));
        }
    }
}

// text-image col-image
.text-image__col-img {
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
    background-position: center;
    height: 220px;
    .flex-col-md(6);

    @media (min-width: @screen-md) {
        height: auto;
    }
}
