// ant-select-inverse
.ant-form-inverse,
.ant-select-dropdown {
    .ant-select {
        .ant-select-selector {
            background-color: #eff1f5;
            border-color: #eff1f5;
            color: #01101f;
        }
    }
}

// ant-select-selection-item-inverse
.ant-form-inverse,
.ant-select-dropdown {
    .ant-select-selection-item {
        color: #01101f;
    }
    .ant-select-single.ant-select-open {
        .ant-select-selection-item {
            color: #788392;
        }
    }
}

// ant-select-arrow-inverse
.ant-form-inverse,
.ant-select-dropdown {
    .ant-select-arrow {
        color: #01101f;
    }
    .ant-select-open {
        .ant-select-arrow {
            color: #788392;
        }
    }
}
