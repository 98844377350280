// hover
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid @neutral-10;
}

// checked
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-color;
    border: 1px solid @primary-color;
    color: @white;
}

// disabled + checked
.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: @primary-4;
    border: 1px solid @primary-4 !important;
    color: @white;
}

// disabled
.ant-checkbox-disabled:not(.ant-checkbox-checked) .ant-checkbox-inner {
    background-color: @neutral-3;
    border: 1px solid @neutral-6 !important;
}

// error (not disabled)
.ant-form-item-has-error:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    border: 1px solid @error-color;
}
