// ant-popover
.ant-popover {
    font-size: @font-size-sm;
    max-width: 300px;

    a {
        color: @popover-color;
    }
}

// ant-popover-inner-content
.ant-popover-inner-content {
    padding: @popover-padding-horizontal;
}
