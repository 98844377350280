// ant-modal
.ant-modal {
    top: 0;
    padding-bottom: 0;
}

// ant-modal-wrap
.ant-modal-wrap {
    display: flex;
    flex-direction: column;

    &:before,
    &:after {
        content: '';
        flex-shrink: 0;
        flex-grow: 1;
        height: 30px;
    }
}

// ant-modal-content
.ant-modal-content {
    border-radius: @border-radius-lg;
    overflow: hidden;
}

// ant-modal-header
.ant-modal-header {
    border-radius: @border-radius-lg @border-radius-lg 0 0;
    padding-top: 50px;

    @media (min-width: @screen-md) {
        padding: 60px 60px 24px;
    }
}

// ant-modal-title
.ant-modal-title {
    font-weight: 500;
    font-family: @heading-font-family;

    @media (min-width: @screen-md) {
        font-size: @heading-3-size;
    }
}

// ant-modal-close-x
.ant-modal-close-x {
    font-size: 20px;
    color: @modal-close-color;

    @media (min-width: @screen-md) {
        width: 60px;
        height: 60px;
        line-height: 60px;
    }
    &:hover,
    &:active {
        color: @black;
    }
}

// ant-modal-body
.ant-modal-body {
    font-size: @font-size-sm;
    padding-top: 0;
    padding-bottom: 0;

    @media (min-width: @screen-md) {
        font-size: @font-size-base;
        padding: 0 60px;
    }
    &:last-child {
        padding-bottom: @modal-body-padding;

        @media (min-width: @screen-md) {
            padding: 0 60px 60px;
        }
    }
}

// ant-modal-footer
.ant-modal-footer {
    border-radius: 0 @border-radius-lg @border-radius-lg 0;
    text-align: right;
    padding: 30px 20px;

    @media (min-width: @screen-md) {
        padding: 40px 60px 60px;
    }
    > .ant-btn {
        & + .ant-btn,
        & + .ant-btn:not(.ant-dropdown-trigger) {
            margin-left: 20px;
        }
    }
}

// ant-modal description
.ant-modal__descr {
    color: @black;
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
}
