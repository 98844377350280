// list-unstyled
.list-unstyled() {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

// layer
.layer(@top, @right, @bottom, @left, @z-index: auto) {
    position: absolute;
    top: @top;
    right: @right;
    bottom: @bottom;
    left: @left;
    z-index: @z-index;
}

// layer-fixed
.layer-fixed(@top, @right, @bottom, @left, @z-index: auto) {
    position: fixed;
    top: @top;
    right: @right;
    bottom: @bottom;
    left: @left;
    z-index: @z-index;
}

// img-responsive
.img-responsive(@display: block) {
    display: @display;
    max-width: 100%;
    height: auto;
}

.text-ellipsis(@lineCount: 2) {
    display: -webkit-box;
    -webkit-line-clamp: @lineCount;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
