.payment-btn-buy {
    padding: 24px 20px;

    &:focus:not(:disabled),
    &:hover:not(:disabled),
    &:active:not(:disabled) {
        background-color: @white;
        border: @border-width-base solid @primary-color;
    }
}

.payment-btn-buy .payment-btn__descr {
    margin-bottom: 0;
    font-size: 14px;
    color: @neutral-7;
}
