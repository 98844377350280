// ant-pagination
.ant-pagination {
    margin: 25px 0 10px;
    text-align: center;
}

// ant-pagination-item
.ant-pagination-item {
    a {
        text-decoration: none;
        color: @text-color;
    }
    &:hover,
    &:active {
        border-color: darken(@border-color-split, 20%);
    }
}

.ant-pagination-item-active {
    border-color: @primary-color;

    a {
        color: @text-color;
    }

    &:focus,
    &:hover,
    &:active {
        border-color: @primary-color;

        a {
            color: @text-color;
        }
    }
}

.ant-pagination-prev,
.ant-pagination-next {
    &:hover,
    &:active {
        & .ant-pagination-item-link {
            color: @text-color;
            border-color: darken(@border-color-split, 20%);
        }
    }

    &.ant-pagination-disabled {
        .ant-pagination-item-link {
            color: @input-placeholder-color;
            &:hover {
                color: darken(@border-color-split, 20%);
            }
        }
    }
}

.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    & .ant-pagination-item-container .ant-pagination-item-link-icon,
    & .ant-pagination-item-container .ant-pagination-item-ellipsis {
        color: @text-color;
    }
}
