// heading
.heading {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 44px 0 16px;
}

// heading title
.heading__title {
    flex: 1 1 auto;
    margin-bottom: 0;
}

// heading controls
.heading__controls {
    flex: 0 0 auto;
    margin-left: 16px;
}

// heading button
.heading__btn {
    &:not(:first-child) {
        margin-left: 15px;
    }
}
