.embedded {
    iframe {
        width: 100%;
        height: 420px;
    }

    table {
        margin: auto;
        border-collapse: collapse;
        text-align: center;

        th,
        td {
            border: solid 1px @neutral-6;
            padding: 6px 8px;
        }
    }

    blockquote {
        padding: 0 24px;
        overflow: hidden;
        margin-right: 0;
        margin-left: 0;
        font-style: italic;
        border-left: solid 6px @neutral-6;
    }

    figure {
        &.image-style-side {
            float: right;

            img {
                display: inline-block;
                max-width: 350px;
                margin: 16px;
            }
        }
    }
}
