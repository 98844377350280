.payment-block {
    margin-top: 48px;
}

.payment-block__row {
    margin-top: 32px;
    .flex-col-lg(8);

    &:not(:first-of-type) {
        margin-top: 24px;
    }
}

.payment-block__heading {
    &:extend(h3);
}

.payment-block__alert {
    margin-bottom: 20px;
}
