.ant-spin-blur {
    overflow: visible;
}

/* CDK spinner */
.ant-spin-dot-item {
    .cdk-overlay-pane & {
        background-color: @white;
    }
}
