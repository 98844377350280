.investor-profile .info-header {
    margin-top: 36px;
}

.investor-profile__subheading {
    &:extend(h4);
    font-weight: normal;
    margin-top: 20px;
}

.investor-profile__kyc {
    width: fit-content;
    margin: 5px 0;
}
