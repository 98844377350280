// invest
.invest {
    background-color: @neutral-2;
    padding: 80px 0px 0px 0px;
    margin-bottom: 75px;

    @media (min-width: @screen-md) {
        padding: 80px 0px 10px 0px;
    }
    @media (min-width: @screen-lg) {
        margin-bottom: 100px;
        padding: 100px 0px 30px 0px;
    }
}

.invest__col {
    .flex-col-md(8);
    .flex-col-xl(5);
}

.invest__col-cards {
    .flex-col-md(6);
    .flex-col-xl(4);

    @media (min-width: @screen-md) {
        margin-bottom: 10px;
    }
}

.invest__heading {
    &:extend(h1);
    font-size: 34px;

    @media (min-width: @screen-xl) {
        font-size: 48px;
    }
}

.invest__subheading {
    margin: 12px 0 38px 0;

    @media (min-width: @screen-md) {
        margin: 24px 0 52px 0;
    }

    @media (min-width: @screen-xl) {
        margin: 28px 0 46px 0;
    }
}
