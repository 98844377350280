// ant-btn
.ant-btn {
    font-size: @font-size-sm;
    text-transform: uppercase;
    background: @neutral-4;

    &:hover,
    &:focus,
    &:active {
        background: @neutral-5;
        border-color: @neutral-5;
        color: @text-color;
    }
    &[disabled],
    &[disabled]:hover {
        background: @neutral-4;
        border-color: @neutral-4;
        color: @neutral-7;
    }
    a.linkify {
        text-decoration: none;
    }
}
