.payment-subtitle {
    margin-top: 11px;
    font-size: @font-size-sm;
    color: @black;
    font-weight: initial;
    white-space: normal;
    text-transform: none;
}

.payment-subtitle__prefix {
    padding-right: 5px;
    text-transform: capitalize;
}

.payment-subtitle__link {
    display: inline-block;
    pointer-events: all;
    color: @black;
}

// Payment Subtitle Modifiers
.payment-subtitle-faded {
    color: @normal-color;
}

// Payment Subtitle Hint
.payment-subtitle-hint {
    margin-left: 6px;
}

.payment-subtitle-block {
    font-size: @font-size-base;
    border-bottom: @border-width-base solid @divider-color;
    padding: 14px 0 24px 0px;
    margin-bottom: 24px;
}

.payment-subtitle-withdraw {
    padding-top: 0;
}

.payment-subtitle-cta {
    display: inline-block;
    margin-top: 32px;
    font-size: @font-size-base;
    text-transform: capitalize;
}
