// table-responsive
.table-responsive {
    @media (max-width: @screen-sm-max) {
        overflow-x: scroll;
    }
    > .ant-table-wrapper {
        @media (max-width: @screen-sm-max) {
            min-width: (@container-max-width-md - @gutter);
        }
    }
}
