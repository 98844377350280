.iframe {
    background: #ffffff;
    display: block;
    overflow: hidden;
}

.iframe iframe {
    border: none;
    width: 100%;
    height: 100%;
}

// iframe ach
.iframe-ach {
    height: 633px;
    width: 360px;
}

// iframe docusign
.iframe-docusign {
    height: 80vh;
    max-height: 790px;
    width: 100%;
    max-width: 1030px;
}

// iframe stripe
.iframe-stripe {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    margin: 24px auto;

    @media (min-width: @screen-md) {
        max-width: 460px;
        margin: 0 auto;
    }
}

.iframe-stripe iframe {
    height: 340px;
    width: unset;
    margin: -10% -15%;

    @media (min-width: @screen-sm) {
        height: 360px;
    }
}
