.payment-btn-item {
    padding: 24px 20px;
    flex-wrap: wrap;
    gap: 30px 90px;
    justify-content: space-between;
    .bordered-list(@divider-color);

    &:focus:not(:disabled),
    &:hover:not(:disabled),
    &:active:not(:disabled) {
        background-color: @white;
        border: @border-width-base solid @primary-color;
    }

    &:not(.payment-btn-with-details) {
        padding-top: 35px;
        padding-bottom: 35px;
    }
}
