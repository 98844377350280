.bullets__container {
    .bullets-img & {
        background-color: @neutral-2;
    }
}

.bullets__label {
    .bullets-img & {
        display: flex;
        flex-basis: 100%;
        justify-content: center;
        font-weight: bold;

        @media (min-width: @screen-lg) {
            flex-basis: 10%;
            align-items: center;
            margin-bottom: initial;
        }

        @media (min-width: @screen-xl) {
            flex-basis: 15%;
        }
    }
}

.bullets__image {
    .bullets-img & {
        display: flex;
        flex-basis: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 15px;
        row-gap: 24px;

        @media (min-width: @screen-md) {
            margin: initial;
        }

        @media (min-width: @screen-lg) {
            flex-basis: 90%;
        }

        @media (min-width: @screen-xl) {
            flex-basis: 85%;
        }
    }
}

.bullets__imgcol {
    .bullets-img & {
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        @media (min-width: 440px) {
            margin: 0 25px;
        }

        @media (min-width: @screen-sm) {
            margin: 0 55px;
        }

        @media (min-width: @screen-md) {
            margin: 0;
        }

        &:nth-child(3) {
            background-position: left;

            @media (min-width: @screen-md) {
                background-position: center center;
            }
        }
    }
}

.bullets__img-content {
    height: 36px;

    @media (min-width: @screen-lg) {
        height: 47px;
    }
}
