// ant-menu-submenu
.ant-menu-submenu {
    &.ant-menu-submenu-placement-bottom {
        top: -15px;
    }
}

// ant-menu-sub
.ant-menu-sub {
    .ant-menu-submenu & {
        border-radius: 0 0 @border-radius-base @border-radius-base;
        min-width: 141px;
        padding: 10px 0;
    }
}

// ant-menu-submenu-items
.ant-menu-item {
    .ant-menu-submenu & {
        padding: 0 20px;

        &:hover,
        &:active {
            background: @menu-item-active-bg;
        }
        > a {
            &,
            &:hover,
            &:active {
                color: @black;
            }
        }
    }
}
