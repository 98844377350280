// ant-form-item
.ant-form-item {
    margin-bottom: 3.2rem;
}

// .ant-form-item-vertical
.ant-form-item {
    .ant-form-vertical & {
        position: relative;
    }
}

// ant-form-item-control
.ant-form-item-control {
    clear: both;
}

// ant-form-item-explain & -extra
.ant-form-item-explain,
.ant-form-item-extra {
    font-size: @font-size-sm;
}

// .ant-form-item-explain
.ant-form-item-explain {
    padding-top: 3px;
}

// ant-form-help
.ant-form-help {
    .layer(0, 0, auto, auto);
    font-size: @font-size-xs;
    margin-top: 4px;
}
