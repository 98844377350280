.purchase-details .purchase__subheading {
    margin-top: 60px;
    display: none;

    @media (min-width: @screen-md) {
        display: block;
    }
}

.purchase-details__kyc {
    width: fit-content;
    margin-top: 40px;
}

.purchase-details__personal {
    margin-top: 48px;
}

.purchase-details__kyc + .purchase-details__personal {
    margin-top: 32px;
}
