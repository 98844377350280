.auth-page {
    margin: 120px auto;
    padding: 60px;
    max-width: 518px;
    background: @neutral-3;
}

.auth-modal-styler {
    .ant-modal__descr {
        margin-bottom: 3rem;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .ant-modal__title {
        margin-bottom: 1.6rem;
    }
}
