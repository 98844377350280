.item-transaction {
    display: flex;
    justify-content: space-between;
}

.item-transaction__fee {
    display: inline-flex;
}

.item-transaction__hint {
    color: @normal-color;
    margin-left: 16px;
}
