.payment-btn-list .payment-btn__descr {
    font-size: @font-size-sm;
    color: @normal-color;
    line-height: 16px;
    padding-right: 30px;
}

.payment-btn-list .payment-btn__descr-error {
    color: @error-color;
}

.payment-btn-list:not(:disabled) .payment-btn__highlight {
    color: @black;
}
