.steps-checkbox {
    display: flex;
    gap: 12px;
}

.steps-checkbox__desc-consent {
    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
    }
}
