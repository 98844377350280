.purchase-done__icon {
    height: 105px;
    width: 105px;
}

.purchase-done .purchase__heading {
    margin-top: 36px;
}

.purchase-done__btn {
    margin-top: 12px;
}
