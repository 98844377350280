.info-header {
    display: flex;
    gap: 10px;
    align-items: baseline;
}

.info-header__title {
    &:extend(h3);
}

.info-header__title-compact {
    margin-bottom: 0;
}

.info-header__icon a {
    font-size: @font-size-sm;
    color: @neutral-7;

    &:hover,
    &:active,
    &:focus {
        color: @neutral-9;
    }
}
