// ant-empty
.ant-empty {
    margin: 90px auto;
    max-width: 450px;
}

// ant-empty-image
.ant-empty-image {
    height: 60px;
    margin-bottom: 26px;

    .icon {
        height: 60px;
        width: 60px;
    }
}

// ant-empty-description
.ant-empty-description {
    &:extend(h3);
    margin-bottom: 16px;
}

// ant-empty-footer
.ant-empty-footer {
    color: @normal-color;
}
