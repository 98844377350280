.hero {
    background: linear-gradient(rgba(0, 0, 0, 0.49), rgba(0, 0, 0, 0.49)),
        url('/assets/images/hero-banner.jpeg') no-repeat center center / cover;
    color: white;
    margin-bottom: 75px;

    @media (min-width: @screen-lg) {
        margin-bottom: 100px;
    }
}

.hero__container {
    padding: 70px 15px 0px 15px;

    @media (min-width: @screen-md) {
        padding: 80px 15px 30px 15px;
    }

    @media (min-width: @screen-lg) {
        padding: 115px 15px;
    }
}

.hero__text {
    @media (min-width: @screen-md) {
        padding-right: 100px;
    }

    @media (min-width: @screen-lg) {
        padding-right: 150px;
    }
}

.hero__heading {
    &:extend(h1);

    font-size: 34px;
    color: white;

    @media (min-width: @screen-md) {
        font-size: 48px;
    }

    @media (min-width: @screen-xl) {
        font-size: 60px;
    }
}

.hero__subheading {
    margin-top: 12px;
}

.hero__btn {
    margin-top: 24px;

    @media (min-width: @screen-md) {
        margin-top: 27px;
        font-size: 40px;
    }
}

.hero__row {
    position: initial;

    @media (min-width: @screen-lg) {
        position: relative;
    }
}

.hero__col-text {
    .flex-col-lg(8);
}

.hero__col-img {
    background: url('/assets/images/hero-img.png') no-repeat center center / contain;
    height: 400px;
    width: 100%;
    margin-top: 0px;
    .flex-col-lg(4);

    @media (min-width: @screen-md) {
        height: 470px;
        margin-top: 30px;
        background-position: left center;
    }

    @media (min-width: @screen-lg) {
        height: auto;
        margin-top: 0;
        scale: 1.2;
    }

    @media (min-width: @screen-xl) {
        scale: 1.5;
        background-position: right center;
    }
}
