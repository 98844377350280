// main-menu
.main-menu {
    .layer(auto, auto, 100%, auto);
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s ease 0s;
    box-shadow: 0 2px 5px 0 rgba(darken(@body-background, 3%), 0.3);
    padding: 30px 0;
    width: 100%;
    opacity: 0;

    @media (min-width: @screen-lg) {
        flex-direction: row;
        background: transparent;
        box-shadow: none;
        border: none;
        position: relative;
        top: 0;
        margin-right: -15px;
        padding: 0;
        width: auto;
        opacity: 1;
    }

    .ant-menu-item {
        a {
            padding-bottom: 8px;
        }
        &.ant-menu-item-selected {
            a {
                border-bottom: solid 5px @primary-color;
            }
        }
    }

    &.ant-menu-horizontal:not(.ant-menu-dark) {
        .ant-menu-submenu.ant-menu-submenu-horizontal,
        .ant-menu-item {
            &:after {
                border-bottom: none;
            }
            .submenu-custom-title {
                padding-bottom: 8px;
            }
            &.ant-menu-submenu-selected {
                .submenu-custom-title {
                    border-bottom: solid 5px @primary-color;
                }
            }
        }
    }
}

// main-menu-open
.main-menu-open {
    top: 100%;
    bottom: auto;
    opacity: 1;

    @media (min-width: @screen-lg) {
        top: 0;
    }
}

// main-menu-item
.ant-menu-item {
    .main-menu.ant-menu-horizontal > & {
        margin-bottom: 30px;

        @media (min-width: @screen-lg) {
            margin-bottom: 0;
        }
        &,
        &:hover,
        &:active {
            background: none;

            @media (max-width: @screen-md-max) {
                border: none;
            }
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

// main-menu wrap
.main-menu__wrap {
    display: flex;
    max-width: 375px;

    > .ant-btn {
        flex-grow: 1;

        & + .ant-btn {
            margin-left: 20px;
        }
    }
}

// main-menu divider
.ant-dropdown-menu-item-divider {
    .main-menu.ant-menu-horizontal > & {
        margin-top: 0;
        margin-bottom: 30px;
    }
}
