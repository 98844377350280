// payment-button
.payment-btn {
    border: none;
    border-bottom: @border-width-base solid @divider-color;
    display: flex;
    align-items: center;
    border-radius: 0;
    font-size: clamp(@font-size-sm, 3vw, @font-size-base);
    font-weight: 400;
    text-align: left;
    height: auto;
    padding: 20px 0 20px 0;
    background: @white;
    text-transform: none;

    &:focus,
    &:hover,
    &:active {
        background: @white;
        border-color: @primary-color;
        color: @black;
    }

    &[disabled] {
        opacity: 1;

        &,
        &:focus,
        &:hover,
        &:active {
            background: transparent;
        }

        .payment-btn__icon {
            color: @neutral-7;
        }

        .payment-btn__arrow {
            display: inline-flex;
        }
    }

    & + & {
        margin-top: 0;
    }
}

.payment-btn__heading {
    &:extend(h4);
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    color: @black;
}

.payment-btn__highlight {
    font-weight: bold;
}

.payment-btn__title {
    flex-grow: 1;
    margin-right: 10px;
    font-size: @font-size-base;
    text-transform: capitalize;
}

.payment-btn__descr {
    margin-top: 12px;
    white-space: normal;
}

.payment-btn__icon {
    flex-shrink: 0;
    margin-right: 0;
    font-size: 28px;

    .icon {
        color: @neutral-7;
    }
}

.payment-btn__icon-dwolla {
    margin-right: 0;
}

.payment-btn-bordered {
    .bordered-list(@divider-color);
}

.payment-btn-bordered-padding {
    .bordered-list(@divider-color);
    padding: 24px 20px;
    max-height: 90px;

    &:focus,
    &:hover,
    &:active {
        &:not(:disabled) {
            border: @border-width-base solid @primary-color;
        }
    }
}

.payment-btn-borderless {
    border: none;
    box-shadow: none;
}

.payment-btn-inactive {
    pointer-events: none;

    a {
        pointer-events: all;
    }

    &:hover,
    &:focus,
    &:active {
        border: none;
        background: none;
    }
}

// Payment Button Fluid
.payment-btn-fluid {
    display: block;
}

.payment-btn-fluid .payment-btn__title {
    display: inline-block;
}

.payment-btn-fluid .payment-arrow {
    float: right;
}
