// text-section
.text-section {
    margin-bottom: 75px;

    @media (min-width: @screen-lg) {
        margin-bottom: 100px;
    }
}

// text-section column
.text-section__col {
    .flex-col-md(6);
}

// text-section wrap
.text-section__wrap {
    @media (min-width: @screen-xl) {
        padding-left: initial;
        padding-right: 80px;
    }
}

// text-section wrap-right
.text-section__wrap {
    .text-section__col-right & {
        @media (min-width: @screen-xl) {
            padding-right: initial;
        }
    }
}

// text-section heading
.text-section__heading {
    &:extend(h1);
}

// text-section subheading
.text-section__subheading {
    margin-top: 24px;

    @media (min-width: @screen-xl) {
        margin-top: 28px;
    }
}
