.regcf-overview {
    margin-top: 30px;
    border: none;
}

.regcf-overview .ant-card-body {
    background-color: @image-bg;
    padding: 28px 27px 28px 28px;
}

.regcf-overview__section {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.regcf-overview__icon {
    width: 64px;
    height: 64px;
}

.regcf-overview__cta {
    margin-top: 12px;
}
