// payment-info
.payment-info {
    display: flex;
    flex-direction: column;

    @media (min-width: @screen-md) {
        flex-direction: row;
    }

    @media (min-width: @screen-lg) {
        margin-left: 0;
    }
}

// payment-info image
.payment-info__img {
    width: 100%;
    margin: 8px 0px 12px 0px;

    @media (min-width: @screen-md) {
        width: 260px;
        margin: 0px 28px 0px 0px;
    }
}

// payment-info details
.payment-info__details {
    margin-bottom: 5px;
}

.payment-info__descr {
    @media (max-width: @screen-sm-max) {
        text-align: center;
    }
}

.payment-info__title {
    display: block;
    margin-bottom: 16px;
    font-weight: bold;
}

// payment-info subtext
.payment-info__subtext {
    color: @normal-color;
    margin-bottom: 24px;
}

.payment-info__subtext-warning {
    color: @warning-color;
    font-weight: bold;
}
