.payment-item-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    @media (min-width: @screen-md) {
        width: auto;
        justify-content: flex-start;
    }
}
