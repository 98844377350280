.retry-summary-collapse {
    font-size: @font-size-base;
}

.retry-summary-collapse__panel {
    padding: 0 0 10px;
    background-color: @white;
}

.ant-collapse > .retry-summary-collapse__panel.ant-collapse-item > .ant-collapse-header {
    padding: 0;
    position: relative;
    border: none;

    .anticon {
        position: absolute;
        right: 0;
        margin: 0;
    }
}

.retry-summary-collapse__panel .ant-collapse-content {
    margin-top: 20px;
    border: 0;
}

.ant-collapse-borderless > .retry-summary-collapse__panel.ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 0 20px 0;
}
