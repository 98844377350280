// ant-menu-header
.ant-menu {
    .ant-layout-header & {
        line-height: 1.5;

        @media (min-width: @screen-lg) {
            line-height: 80px;
        }
    }
}

// ant-menu-item
.ant-menu-item {
    > a {
        text-decoration: none;
    }
    &:focus {
        outline: none !important;
    }
}

// ant-dropdown-menu-item-divider
.ant-dropdown-menu-item-divider {
    margin-left: 15px;
    margin-right: 15px;
}
