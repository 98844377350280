.item__col {
    display: flex;
    flex-direction: column;
}

.item__heading {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 3rem;
    margin-bottom: 4rem;
    word-break: break-word;

    @media (min-width: @screen-md) {
        margin-top: 2.8rem;
        margin-bottom: 2.8rem;
    }
}

.item__description {
    margin-bottom: 62px;
}

// item description details inner-HTML
.item__description img {
    max-width: 100%;
}

.item__title {
    margin-right: auto;
    margin-bottom: 0;
}

// item favorite
.item__favorite {
    margin-left: 10px;

    @media (min-width: @screen-md) {
        margin-left: 20px;
    }
    > .ant-btn {
        border-color: @black;
        font-size: 15px;
        color: @black;
        height: 32px;
        width: 32px;

        @media (min-width: @screen-md) {
            font-size: 20px;
            height: 48px;
            width: 48px;
        }
        &:hover,
        &:active,
        &:focus {
            background-color: @neutral-4;
        }
    }
}

.item__subtitle {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-top: 0.6rem;
    margin-bottom: 2rem;
}

.item__text {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 1.2rem;
}

.item__block-header {
    display: block;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 500;
}

.item__blocks {
    display: flex;
    flex-wrap: wrap;
}

.item__block-content {
    display: flex;
    flex-direction: column;
    flex: 50%;
    margin-bottom: 45px;
}

// item-block--content-muted
.item__block-content-muted {
    color: @normal-color;
}
