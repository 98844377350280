.ant-btn-primary {
    background: @primary-color;
    color: @white;

    &[disabled],
    &[disabled]:hover {
        background: @primary-4;
        color: @white;
        border-color: @primary-4;
    }

    &:hover,
    &:focus,
    &:active {
        background: @primary-8;
        color: @white;
        border-color: @primary-8;
    }
}
