.regcf-consent .ant-modal-body {
    margin-top: 12px;
    padding-right: 5px;

    @media (min-width: @screen-md) {
        padding: 0 10px 20px 60px;
    }
}

.regcf-consent .ant-checkbox-wrapper {
    font-size: @font-size-sm;
}

.regcf-consent__checkbox,
.regcf-consent__cta {
    padding-right: 20px;

    @media (min-width: @screen-md) {
        padding-right: 50px;
    }
}

.regcf-consent__checkbox {
    margin-bottom: 22px;
}
