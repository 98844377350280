.regcf-agreement {
    max-height: 400px;
    overflow-y: auto;
    padding-right: 20px;
    margin-bottom: 32px;
    color: @black;
    font-size: @font-size-sm;

    @media (min-width: @screen-md) {
        max-height: 450px;
        padding-right: 50px;
    }
}

.regcf-agreement::-webkit-scrollbar {
    width: 4px;
}

.regcf-agreement::-webkit-scrollbar-thumb {
    background-color: @normal-color;
}

.regcf-agreement__list {
    max-height: 400px;
    margin-bottom: 0;

    @media (min-width: @screen-md) {
        max-height: 450px;
    }
}

.regcf-agreement__item + .regcf-agreement__item {
    margin: 16px 0;
}

.regcf-agreement__item a {
    color: @black;
}
