.ant-tabs-bar {
    margin-bottom: 20px;
}

.ant-tabs-nav-container {
    font-size: 1.6rem;
    font-weight: 400;
}

.ant-tabs-nav .ant-tabs-tab {
    padding: 1.5rem 0;
    font-family: @heading-font-family;
    font-size: 16px;

    @media (min-width: @screen-md) {
        font-weight: 500;
        font-size: 20px;
    }

    & + .ant-tabs-tab {
        margin-left: 40px;

        @media (min-width: @screen-md) {
            margin-left: 60px;
        }

        @media (max-width: @screen-sm-max) {
            margin-left: 40px;
        }
    }
}

// ant-tabs-overflow
.ant-tabs-overflow {
    margin-left: -15px;
    margin-right: -15px;

    .ant-tabs-nav {
        margin-left: 15px;
        margin-right: 15px;
    }
    .ant-tabs-bar {
        margin-left: 15px;
        margin-right: 15px;
    }
    .ant-tabs-tabpane {
        padding-left: 15px;
        padding-right: 15px;
    }
}

// small device tab btn
.ant-tabs-tab-btn {
    @media (max-width: @screen-sm-max) {
        font-size: @font-size-base;
    }
}

// active tab bottom border
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 5px;
}
