// bullets
.bullets {
    margin-bottom: 75px;

    @media (min-width: @screen-lg) {
        margin-bottom: 100px;
    }
}

// bullets container
.bullets__container {
    background-color: @primary-2;
    padding: 24px 0;

    @media (min-width: @screen-md) {
        padding: 30px 15px 30px;
    }
    @media (min-width: @screen-lg) {
        .container();
        border-radius: 12px;
        padding: 33px 15px 33px;
    }
}

.bullets__row {
    row-gap: 24px;
    overflow: hidden;
}

.bullets__col {
    display: flex;
    flex-basis: 50%;
    align-items: center;
    text-align: center;
    margin-left: -1px;

    @media (min-width: @screen-md) {
        flex-basis: 25%;
    }

    &::before,
    &::after {
        content: '';
        width: 15px;
        height: 23px;
    }
}

.bullets__col + .bullets__col {
    &::before {
        border-left: solid 1px @primary-4;
    }
}

.bullets__text {
    flex-grow: 1;
}
