.item-financial {
    margin-bottom: 62px;

    @media (min-width: @screen-lg) {
        display: none;
    }
}

.item-financial__header {
    display: block;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 500;
}

.item-financial__name {
    color: @normal-color;
    margin-right: auto;
}

.item-financial__amount {
    font-size: 16px;
    color: @text-color;
}

.item-financial__row {
    display: flex;
    font-size: 16px;
    margin: 24px 0 20px 0;
}

.item-financial__row-progress {
    display: block;
    text-align: center;

    @media (min-width: @screen-md) {
        margin-left: 20%;
    }
}

.item-financial__row-progress .item-financial__name {
    margin-bottom: 6px;
}

.item-financial__divider {
    display: block;

    @media (min-width: @screen-md) {
        margin-top: 8px;
    }
}
