.card-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.card-info__title {
    font-weight: 500;
}

.card-info__funded-progress {
    margin-bottom: 15px;
}

.card-info__details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    color: @text-color;
}

.card-info__name {
    color: @normal-color;
    margin-right: auto;
}

.card-info__amount {
    font-size: 16px;
    color: @text-color;
}

.card-info__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2.4rem;

    &:not(:last-child) {
        margin-bottom: 0.6rem;
    }
}

.card-info__col {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.card-info__alert {
    margin: auto 0 0 0;
    font-weight: bold;

    .ant-alert {
        width: 137px;
        height: 34px;
        border: none;
        border-radius: 4px;

        .ant-alert-content {
            display: flex;
            justify-content: center;
            white-space: nowrap;
            font-size: 14px;
        }
    }
}

.card-info__alert-closed {
    .ant-alert {
        background-color: @success-fade-3;
    }
}

.card-info__alert-coming-soon {
    .ant-alert {
        background: #dbe7ff;
    }
}
