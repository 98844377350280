.ant-progress-show-info .ant-progress-outer {
    padding-right: 0;
}

.ant-progress--squared {
    .ant-progress-inner {
        border-radius: 0;
    }
}

.card-info__funded-progress-funded {
    .ant-progress-bg {
        background: #50a43b;
    }
}
